import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader } from "mdbreact";
import HomePageNews from "./News/HomePageNews";
import { GetData } from "../api/service";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import RecentNews from "./RecentNews";
import HomePageFeatured from "./Essentials/HomePageFeatured";
import Header from "./HeaderComponent/Header";
import { Grid } from "@material-ui/core";
import { Divider } from "antd";
import Testimonials from "./Essentials/Testimonials";
import AchievementsSmall from "./Essentials/AchievementsSmall";
import AchievementLarge from "./Essentials/AchievementLarge";
import AlumniSmall from "./Essentials/AlumniSmall";

export default class HomePagePandu extends Component {
  state = {
    events: [],
    isLoaded: false,
    academicPerformance: [],
  };

  componentDidMount() {
    this.loadData();

  
    GetData(`/getacademicperformance`).then((resp) => {
    
      this.setState({
        academicPerformance: resp,
        isLoaded: true,
      });
    });
    
  }

  loadData = () => {
    GetData(`/getupcomingevents`).then((resp) => {
      console.log("events", resp);
      this.setState({
        events: resp,
        isLoaded: true,
      });
    });
  };
  render() {
    return (
      <div className="fontFix">
        <Header />

        <RecentNews />

        <MDBContainer>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ alignItems: "center", display: "flex" }}
            >
              <img
                style={{ width: "100%" }}
                src={"/images/logo.png"}
                alt="logo"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div
                className="content my-4 p-4 p text-justify"
                style={{
                  // fontFamily: "Dosis",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: 1,
                }}
              >
                <div className="header-style-h1">About the College</div>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pandu College
                  believes in imparting life-oriented and value based education-
                  an education aimed at strengthening the body purifying the
                  mind and sharpening the intellect. It is intended to make the
                  learners aware of the need to cultivate such qualities that
                  would enable them to integrate properly with their
                  communities, the people of the nation and their physical and
                  biological environments. It is so oriented as to help the
                  learners to get exposed to the scientific and technological
                  advancement as well as the economic scenario of the country
                  and the world at large. Such an education will regulate their
                  lives in such a manner as to make them emerge as true citizen
                  of the world possessing the essential human qualities and . .
                  . <a href="/aboutus/about">Read More</a>
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div
                className="content my-4 p-4 p text-justify"
                style={{
                  // fontFamily: "Dosis",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: 1,
                }}
              >
                <div className="header-style-h1" style={{ textAlign: "right" }}>
                  From the Principals Desk
                </div>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Warm greetings
                  to all the current and future stakeholders of Pandu College.
                  As the Principal of this esteemed institution, it gives me
                  great pleasure to represent the Pandu College community.
                  Furthermore, I am proud to say that I have a highly capable,
                  hardworking, and competent team by my side who are dedicated
                  to the development of the institution. I firmly believe that
                  education is the backbone of society, and a well-educated
                  younger generation has the potential to propel our society to
                  the unprecedented height of success. With this belief in mind,
                  I aim to work collaboratively with all stakeholders to
                  establish Pandu College as a distinguished institute within a
                  defined time frame. . <a href="/administrations/principal">Read More</a>
                </p>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ alignItems: "center", display: "flex" }}
            >
              <img
                style={{ width: "100%" }}
                src={"/images/principal_pandu_college.png"}
                alt="logo"
              />
            </Grid>
          </Grid>

          {/* <div className="row">
            <div className="col-md-4 gapround">
              <a href="/aboutus/history">
                <div className="round">
                  <h4>History</h4>
                </div>
              </a>
            </div>
            <div className="col-md-4 gapround">
              <a href="/facility/library">
                <div className="round">
                  <h4>Facilities</h4>
                </div>
              </a>
            </div>

            <div className="col-md-4 gapround">
              <a href="/departments">
                <div className="round">
                  <h4>Departments</h4>
                </div>
              </a>
            </div>
          </div> */}
        </MDBContainer>

        <div className="news py-4" style={{ backgroundColor: "#404040" }}>
          <MDBContainer>
            <p
              style={{
                color: "white",
                fontSize: "3em",
                fontWeight: 600,
                textAlign: "center",
                margin: "2em 0 1em 0",
                fontFamily: "bassy",
              }}
            >
              Notifications
            </p>
            <br />

            <div className="row news-container">
              <div className="col-md-4 p-4">
                <div style={{ backgroundColor: "white", padding: 10 }}>
                  <Link to="/notifications/academic">
                    <h2 className="subhead">Student Notification</h2>
                    <div className="noticecard">
                      <HomePageNews type="COLLEGE_ACADEMIC_NOTICE" />
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 p-4">
                <div style={{ backgroundColor: "white", padding: 10 }}>
                  <Link to="/notifications/tender">
                    <h2 className="subhead">Tenders</h2>
                    <div className="noticecard">
                      <HomePageNews type="COLLEGE_TENDER" />
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 p-4">
                <div style={{ backgroundColor: "white", padding: 10 }}>
                  <Link to="/notifications/administrative">
                    <h2 className="subhead">Announcements</h2>
                    <div className="noticecard">
                      <HomePageNews type="COLLEGE_ADMIN_NOTICE" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </MDBContainer>
        </div>

        <div>
          <MDBContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Divider orientation="left">
                  <p
                    style={{
                      color: "#984815",
                      fontSize: "3em",
                      textAlign: "center",
                      margin: "2em 0 1em 0",
                      fontFamily: "bassy",
                    }}
                  >
                    Awards and Achievements
                  </p>
                </Divider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Divider orientation="left">
                  <p
                    style={{
                      color: "#984815",
                      fontSize: "3em",
                      textAlign: "center",
                      margin: "2em 0 1em 0",
                      fontFamily: "bassy",
                    }}
                  >
                    Notable Alumni
                  </p>
                </Divider>
              </Grid>
            </Grid>
          </MDBContainer>
          <br />
          <div
            style={{
              backgroundColor: "#4A452A",
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <MDBContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <AchievementsSmall />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <AlumniSmall />
                </Grid>
              </Grid>
            </MDBContainer>
          </div>
        </div>

        <div>
          <MDBContainer>
            <Divider orientation="left">
              <p
                style={{
                  color: "#984815",
                  fontSize: "3em",
                  textAlign: "center",
                  margin: "2em 0 1em 0",
                  fontFamily: "bassy",
                }}
              >
                Academic Performance
              </p>
            </Divider>

            <br />

            <div className="row my-4">
              {this.state.academicPerformance.map((el, index) => (
                <div key={index} className="col-md-2 col-sm-12 my-2">
                  <a target="_blank" href={el.url}>
                    <div
                      className="card p-4 text-center text-dark"
                      style={{ background: "transparent", cursor: "pointer" }}
                    >
                      <div className="d-flex justify-content-center">
                        <img
                          src="/images/icons/new/performance.png"
                          style={{
                            //height: "80px",
                            width: "100px",
                            margin: "10px",
                          }}
                        />
                      </div>
                      <h4>{el.percentage} </h4>
                      <h6>{el.course}</h6>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </MDBContainer>
        </div>

        <MDBContainer>
          <div className="row my-4">
            <div className="col-md-4 col-sm-12 my-2">
              <a href="/facility/cgpc">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/new/CGPC.png"
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>CGPC</h4>
                  <p>Career Guidance and Placement Cell</p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="/iqac/strategies">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/new/IQAC.png"
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>IQAC</h4>
                  <p>The Internal Quality Assurance Cell</p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="/facility/library">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/new/Library.png"
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>Central Library</h4>
                  <p>Features in our library</p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="/facility/science-forum">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/new/sf-pc.jpeg"
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>Pandu College Science Forum</h4>
                  <p>Science Forum</p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="/facility/clubs">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/new/Clubs.png"
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>Clubs</h4>
                  <p>Different clubs of the College</p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="/facility/iic">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/new/iic-pc.png"
                      style={{
                        height: "150px",
                        width: "150px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>IIC</h4>
                  <p>Institutional Innovative Council</p>
                </div>
              </a>
            </div>
          </div>
        </MDBContainer>

        <div>
          <MDBContainer>
            <div className="row my-4">
              <div className="col-md-2 col-sm-12 my-2">
                <a href="/facility/ncc">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      height: 180,
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/ncc-new-logo.png"
                        style={{
                          height: "90px",
                          width: "90px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <h4 style={{fontSize: 14}}>NCC</h4>
                    <h6></h6>
                  </div>
                </a>
              </div>

              <div className="col-md-2 col-sm-12 my-2">
                <a href="/facility/nss">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      height: 180,
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/new/nss.jpg"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <h4 style={{fontSize: 14}}>NSS</h4>
                    <h6></h6>
                  </div>
                </a>
              </div>

              <div className="col-md-2 col-sm-12 my-2">
                <a href="/facility/dc">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      height: 180,
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/new/distance-education.png"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <h4 style={{fontSize: 14}}>Distance Education</h4>
                    <h6></h6>
                  </div>
                </a>
              </div>

              <div className="col-md-2 col-sm-12 my-2">
                <a href="/facility/pcsu">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      height: 180,
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/new/pcsu.jpg"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <h4 style={{fontSize: 14}}>PCSUS</h4>
                    <h6></h6>
                  </div>
                </a>
              </div>

              <div className="col-md-2 col-sm-12 my-2">
                <a href="/facility/wc">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      height: 180,
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/wc-pandu-1.jpeg"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <h4 style={{fontSize: 14}}>Pandu College Women Cell</h4>
                    <h6></h6>
                  </div>
                </a>
              </div>

              <div className="col-md-2 col-sm-12 my-2">
                <a href="/facility/pc">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      height: 180,
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/new/print-publication.jpg"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <h4 style={{fontSize: 14}}>Pandu College Publication Cell</h4>
                    <h6></h6>
                  </div>
                </a>
              </div>
            </div>
          </MDBContainer>
        </div>

        <div className="news" style={{ backgroundColor: "#404040" }}>
          <MDBContainer>
            <p
              style={{
                color: "white",
                fontSize: "3em",
                textAlign: "center",
                margin: "2em 0 0 0",
                fontFamily: "bassy",
              }}
            >
              Take A Tour Of Pandu College
            </p>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 my-4 vdamaz">
                <iframe
                  width="100%"
                  style={{ aspectRatio: "1.6410" }}
                  src="https://www.youtube.com/embed/pslw4nuqSHc?si=0SkimeH0xG4c9MRE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-md-2"></div>
            </div>
          </MDBContainer>
        </div>

        <div style={{ backgroundColor: "#EEECE1" }}>
          <MDBContainer>
            <Divider orientation="left">
              <p
                style={{
                  color: "#984815",
                  fontSize: "3em",
                  textAlign: "center",
                  margin: "0 0 1em 0",
                  fontFamily: "bassy",
                }}
              >
                Comments of Guests, Visitors & Alumni
              </p>
            </Divider>

            <br />

            <Testimonials />
          </MDBContainer>
        </div>

        <HomePageFeatured />
      </div>
    );
  }
}
